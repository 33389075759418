import React, { memo } from "react"
import { Link} from "gatsby"
import { Spin } from "antd"

const CareerSecond = ({ careerData }) => {
  return (
    <div className="career">
      <h1 className="career-Available-Heading">Available Opportunities</h1>
      <div className="careerSecond">
        {careerData.length ? (
          careerData.map(car => {
            return (
              <div key={car.id}>
                <Link
                  to={`/career/details?id=${car.id}`}
                  style={{ marginLeft: "12px" }}
                >
                  <span style={{ color: "#000000" }}>
                    <h3>{car.display_name}</h3>
                    {car.short_description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: car.short_description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Link>
                <Link to={`/career/details?id=${car.id}`}>
                  <button>Apply</button>
                </Link>
              </div>
            )
          })
        ) : (
          <div
            style={{
              backgroundColor: "#fff",
              margin: "auto",
              width: "300px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin size="large" style={{ backgroundColor: "#fff" }} />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(CareerSecond)
