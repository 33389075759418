import React, { memo, useState } from 'react'
import InfoJobModal from "../components/InfoJobModal"
import { Link } from 'gatsby'
import Img from 'gatsby-image'
const CareerHeader = ({ data }) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const backgroundImage = data.careerBg.childImageSharp.fluid.src
  return (
    <div className='career'>
      <div className='career-Conatiner'
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className='career-logo'>
          <Link to='/' >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} alt='' />
          </Link>
        </div>
        <div className='careerHeader'>
          <h1>
            Join our thriving team as we expand and grow! Are you ready to be a valuable part of our journey?
          </h1>
          <Link >
            <button onClick={showModal}>Join Us</button>
            <InfoJobModal
              jobName={''}
              companyIsEnabled
              url="http://46.101.195.121:8066/email/ContactUs"
              isModalVisible={isModalVisible}
              handleOk={handleOk}
              handleCancel={handleCancel}
            />
          </Link>
        </div>
      </div>

    </div>
  )
}

export default memo(CareerHeader)
