import React, { memo } from "react"
import IconsAdded from "./IconsAdded"

const CareerFirst = () => {
  return (
    <div className="career position-relative">
      <div class="careerFirst">
        <div class="div1">
          <h3>
            Continuous Learning and Holistic  Development
          </h3>
          <p>
            International Projects, Regular Learning Circles, Training and

            certifications
          </p>
        </div>
        <div class="div2">
          <h3>
            Harmonizing Work and Life:  Achieving  Balance and
            Well-being
          </h3>
          <p>
            Remote work, Flexible work hours, Family-friendly policies,
            Generous time off, in door activities
          </p>
        </div>
        <div class="div3">
          <h3>
            Comprehensive Rewards Package  and  Perks
          </h3>
          <p>
            Long stay awards, Bi-annual performance reviews, Annual
            increments, Fuel allowance
          </p>
        </div>
        <div class="div4">
          <h3>

            Fostering a Culture of Belonging and  Equality
          </h3>
          <p>We ensure equal opportunity to all, no office politics </p>
        </div>
      </div>
    </div>
  )
}

export default memo(CareerFirst)
