import React, { useState, useEffect } from "react"
import {
  Typography,
  Modal,
  Input,
  Upload,
  Button,
  Form,
  message,
  Spin,
  Select,

} from "antd"
import ReCAPTCHA from "react-google-recaptcha"
import { UploadOutlined, CaretDownOutlined } from "@ant-design/icons"
import "../styles/infoModal.scss"
import { getBase64 } from "./../utils/utils"
import { emails } from "../data"
import axios from "axios"
const InfoJobModal = ({
  url,
  companyIsEnabled,
  isModalVisible,
  handleOk,
  handleCancel,
  id,
  jobName,
}) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [captchaValue, setCaptchaValue] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [position, setPostion] = useState()
  const [selectedId, setSelectedId] = useState()
  const handleChange = value => {
    let findIDOFPosition = position.find(obj => obj.name === value)
    setSelectedId(findIDOFPosition.id)
  }

  let loadSpin = false
  const loadingSpinner = () => {
    loadSpin = true
    return ""
  }
  const handleHover = () => {
    setIsHovered(!isHovered)
  }

  const { Title } = Typography
  const { TextArea } = Input

  const onCaptchaChange = value => {
    setCaptchaValue(value)
  }

  const normFile = e => {
    if (!fileList?.length) {
      return []
    }
    return Array.isArray(e) ? e : e && e.fileList
  }

  const handleFormData = async values => {
    const {
      name,
      email,
      phone,
      designation,
      coverLatter,
    } = values
    getBase64(values.file[0].originFileObj).then(data => {
      const formData = {
        name: designation,
        partner_name: name,
        job_id: jobName ? id : selectedId,
        email_from: email,
        partner_mobile: phone,
        partner_phone: phone,
        description: coverLatter,
        attachment_base64: data,
        email_cc: emails.hr,
      }

      sendFormData(formData, url)
    })
  }

  const onFinish = values => {
    handleFormData(values)
  }

  const uploadProps = {
    fileList,
    accept: ".png, .jpg, .jpeg, .pdf, .doc, .docx,",
    maxCount: 1,
    onRemove: file => {
      setFileList([])
    },
    beforeUpload: file => {
      const isLt200Kb = file.size / 1024 < 200
      if (!isLt200Kb) {
        message.error("File must be smaller than 200kb!")
        setFileList([])
        return false
      } else {
        setFileList([file])
      }
         return false
    },
  }
  const sendFormData = async data => {
    try {
      setLoading(true)
      const response = await axios.post(
        "https://staging.visiomate.com/jobApplication",
        data
      )
      setLoading(false)
      form.resetFields()
      handleOk()
      await message.success("Application submitted successfully!")
    } catch (error) {
      console.error("Error:", error)
      message.error("Failed to submit application. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    axios.get("https://staging.visiomate.com/jobpositions").then(res => {
      setPostion([...JSON.parse(res.data.result).data])
    })
  }, [])
  return (
    <Modal
      centered
      width="80vw"
      visible={isModalVisible}
      onOk={handleOk}
      className="infoModal"
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
    >
      <Title level={3} style={{marginBottom:'1rem'}}>Join Us</Title>

      <Form
        form={form}
        name="about"
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        initialValues={{
          designation: jobName,
        }}
        autoComplete="off"
      >
        <Form.Item
          label=""
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label=""
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Email" type="email" />
        </Form.Item>

        <Form.Item
          label=""
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your Phone!",
            },
            {
              pattern: /^\+92\d{3}\d{7}$/,
              message: 'Please enter a valid phone number "+923000000000"',
            },
          ]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item
          name="designation"
          rules={[
            {
              required: true,
              message: "Please Select Job Designation!",
            },
          ]}
        >
          <Select
            loading={!position?.length}
            onChange={handleChange}
            defaultValue={jobName}
            placeholder="Select Designation"
            options={position?.map(pos => {
              return {
                label: pos.name,
                value: pos.name,
              }
            })}
            suffixIcon={<CaretDownOutlined className="CaretDownOutlined" />}
            style={{borderRadius:'5px'}}
          />
        </Form.Item>
        <Form.Item label="" name="coverLatter" rules="">
          <TextArea
            placeholder="Cover Letter"
            maxLength={100}
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
          <p style={{textAlign:"end", marginBottom:"0"}}>100 Characters</p>
        </Form.Item>
        <Form.Item
          label=""
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please upload Resume!",
            },
          ]}
          style={{marginBottom:"0"}}
        >
          <Upload {...uploadProps} >
            <Button icon={<UploadOutlined />} >Upload Your Resume
            </Button>
          </Upload>
           
        </Form.Item>
        <span className="file-limit-info">Max. size 200KB</span>
        <div className="g-recaptcha ">
        <ReCAPTCHA
          sitekey="6Le4Xc4pAAAAACdoP1-v8vNKtHOYfkJiXrOBvi8V"
          onChange={onCaptchaChange}
          style={{marginTop:"1rem"}}
        />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span className="submit" style={{ width: 120, marginRight: 15 }}>
            <a
              href="#"
              disabled={captchaValue === null}
              onClick={e => {
                e.preventDefault()
                if (captchaValue === null) {
                  return
                }
                form.submit()
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              data-front={
                loading ? loadingSpinner() : isHovered ? "Submit" : "Submit"
              }
              data-back={
                loading ? loadingSpinner() : isHovered ? "Submit" : "Submit"
              }
            />
            {loadSpin ? <Spin className="spinner-for-proposal-form" /> : ""}
          </span>
        </div>
      </Form>
    </Modal>
  )
}

export default InfoJobModal
